import * as TAXES from "./taxes";
import * as EMPLOYEE_TAXES from "./employee-taxes";
import * as EMPLOYEE_ALLOWANCES from "./employee-allowances";
import * as EMPLOYEE_DEDUCTIONS from "./employee-deductions";
import * as PAYROLLS from "./payrolls";
import * as PAYSLIPS from "./payslips";
import * as DEDUCTIONS from "./deductions";
import * as CONTRACTS from "./contracts";
import * as ALLOWANCES from "./allowances";
import * as EMPLOYEES from "./employees";
import * as GENERAL_SETTINGS from "./general-settings";
import * as USERS from "./users";
import * as EXPORT_MONTHLY_REPORT from "./export-monthly-reports";

export {
  TAXES,
  EMPLOYEE_TAXES,
  PAYROLLS,
  PAYSLIPS,
  DEDUCTIONS,
  CONTRACTS,
  ALLOWANCES,
  EMPLOYEE_ALLOWANCES,
  EMPLOYEES,
  EMPLOYEE_DEDUCTIONS,
  GENERAL_SETTINGS,
  USERS,
  EXPORT_MONTHLY_REPORT
};
