import axiosInstance from "app/axios-interceptor";

export const getPayslips = async (params, signal) => {
  const { data } = await axiosInstance.get(`payslip`, { params, signal });
  return data;
};

export const getPayslipDetail = async (id, params, signal) => {
  const { data } = await axiosInstance.get(`payslip/${id}`, { params, signal });
  return data;
};
export const createPayslip = async (payload) => {
  const { data } = await axiosInstance.post(`payslip`, payload);
  return data;
};
export const updatePayslip = async (id, payload) => {
  const { data } = await axiosInstance.put(`payslip/${id}`, payload);
  return data;
};
export const deletePayslip = async (id) => {
  const { data } = await axiosInstance.delete(`payslip/${id}`);
  return data;
};
